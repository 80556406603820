var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dataset", {
    attrs: { "ds-data": _vm.contracts },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var ds = ref.ds
          return [
            _c("div", { staticClass: "row mb-2" }, [
              _c(
                "div",
                { staticClass: "col-md-6 mb-2 mb-md-0" },
                [_c("dataset-show")],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-6" },
                [
                  _c("dataset-search", {
                    attrs: {
                      "ds-search-placeholder": "Recherche ...",
                      wait: 300
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("dataset-item", {
                    staticClass: "form-row mb-3",
                    staticStyle: {
                      "overflow-y": "auto",
                      "max-height": "400px"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            var rowIndex = ref.rowIndex
                            return [
                              _c(
                                "div",
                                { staticClass: "col-md-4" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name:
                                            "monitoring_and_management.contract.dashboard",
                                          params: { uid: row.uuid }
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "card mb-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "card-body pt-3 pb-2 px-3"
                                          },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "card-title text-truncate mb-2",
                                                attrs: {
                                                  title: "Index: " + rowIndex
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  { attrs: { href: "" } },
                                                  [
                                                    _vm._v(
                                                      "Contrat N°: #" +
                                                        _vm._s(row.ref)
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "card-subtitle text-truncate text-muted mt-2 mb-2"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v("Type de contrat: "),
                                                    _c("b", [
                                                      _vm._v(_vm._s(row.type))
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "card-subtitle text-truncate text-muted mt-2 mb-2"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v("Prestataire: "),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.supplier.prestname
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "card-subtitle text-truncate text-muted mt-2 mb-2"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v("Prestations:: "),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.supplier.prestname
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "card-subtitle text-truncate text-muted mt-2 mb-2 float-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mt-2 text-success"
                                                  },
                                                  [
                                                    _vm._v("Montant Global: "),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.amounts
                                                            .planned_amount
                                                            .withCurrency
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "noDataFound",
                          fn: function() {
                            return [
                              _c("div", { staticClass: "col-md-12 pt-2" }, [
                                _c("p", { staticClass: "text-center" }, [
                                  _vm._v("Aucun contrat n'est trouvé")
                                ])
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-md-row flex-column justify-content-between align-items-center"
              },
              [
                _vm.contracts.length > 0
                  ? _c("dataset-info", { staticClass: "mb-2 mb-md-0" })
                  : _vm._e(),
                _vm.contracts.length > 0 ? _c("dataset-pager") : _vm._e()
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }