var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3"),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            {
              staticClass: "float-right",
              staticStyle: { "font-size": "18px" }
            },
            [
              _c(
                "download-excel",
                {
                  staticClass: "float-right ml-2",
                  staticStyle: { cursor: "pointer" },
                  attrs: {
                    fields: _vm.excel_header,
                    worksheet: "Situation Contrats",
                    name: "SituationContrats.xls",
                    data: _vm.contracts
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      width: "20",
                      src: require("@/assets/images/base/excel.png")
                    }
                  })
                ]
              ),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover",
                      value: "Activer le mode KANBAN",
                      expression: "`Activer le mode KANBAN`",
                      modifiers: { hover: true }
                    }
                  ],
                  class: _vm.showKanban ? "activeActionLink" : "",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showKanbanView($event)
                    }
                  }
                },
                [_c("i", { staticClass: "fab fa-trello" })]
              ),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover",
                      value: "Activer le mode TABLEAU",
                      expression: "`Activer le mode TABLEAU`",
                      modifiers: { hover: true }
                    }
                  ],
                  class: _vm.showTable ? "activeActionLink" : "",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showListView($event)
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-list ml-2" })]
              )
            ],
            1
          )
        ])
      ]),
      _vm.showKanban
        ? _c("KanbanView", { attrs: { contracts: _vm.contracts } })
        : _vm._e(),
      _vm.showTable
        ? _c("ListView", { attrs: { contracts: _vm.contracts } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }