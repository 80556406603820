<template>
  <div>
    <h3></h3>
    

    <!-- KANBAN -->
    <div class="row">
      <div class="col-md-12">
        <div class="float-right" style="font-size: 18px">
          <download-excel 
            :fields="excel_header"
            style="cursor:pointer" 
            class="float-right ml-2" 
            worksheet="Situation Contrats"
            name="SituationContrats.xls"
            :data="contracts">
              <img width="20" :src="require('@/assets/images/base/excel.png')" />
            </download-excel>
          <a v-b-popover.hover="`Activer le mode KANBAN`" @click.prevent="showKanbanView" :class="showKanban ? 'activeActionLink' : ''" href=""><i class="fab fa-trello"></i></a>
          <a v-b-popover.hover="`Activer le mode TABLEAU`" @click.prevent="showListView" :class="showTable ? 'activeActionLink' : ''" href=""><i class="fas fa-list ml-2"></i></a>
        </div>
      </div>
    </div>
    <KanbanView v-if="showKanban" :contracts="contracts"/>
    <ListView v-if="showTable" :contracts="contracts"/>

  </div>
</template>

<script>
import users from "./test.json";
import KanbanView from "./components/list/kanban";
import ListView from "./components/list/list";
import { fetchContractsListApi } from "@/api/common";
import {
  filterList,
  clone,
  isoDateToEuroDate,
  searchAsEuroDate,
} from "./utilities";
export default {
  name: "Example2",
  components: {
    KanbanView,
    ListView
  },
  import : {fetchContractsListApi},
  data: function() {
    return {
      showKanban: false,
      showTable : true,
      users: users,
      contracts: [],
      showNesInvoiceModal: false,
      startsWith: "",
      onlineFilter: "",
      statusClass: {
        Active: "text-success",
        Away: "text-warning",
        "Do not disturb": "text-danger",
        Invisible: "text-secondary",
      },

      excel_header: {
        "Référence": "ref",
        "Prestataire": "supplier.name",
        "Type de prestation": "type",
        "Date Début": "startDate",
        "Date Fin": "endDate",
        "Date de signature": "contract_signature_date",
        "Date d’entrée en vigueur": "contract_effective_date",
        "Montant alloué": "amounts.planned_amount.withCurrency",
        "Réalisation": "amounts.realized_amount.withCurrency",
        "Montant Disponible": "leftAmnt.withCurrency",
      },

      firstNameAsc: true,
    };
  },
  computed: {
    sortFirstName() {
      return this.firstNameAsc ? "firstName" : "-firstName";
    },
  },
  mounted() {
    this.fetchContractsList();
  },
  
  methods: {
    showKanbanView(){
      this.showKanban = true;
      this.showTable = false;
    },
    showListView(){
      this.showKanban = false;
      this.showTable = true;
    },
    filterList,
    fetchContractsList() {
      var loader = this.$loading.show();
      fetchContractsListApi('approved')
      .then((res) => {
        loader.hide();
        this.contracts = res.data.list
      })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {
          //Perform action in always
        });
    },

    showNewInvoiceModalClick() {
      this.showNesInvoiceModal = true;
    },

    handleCloseNewInvoiceModalEvent() {
      this.showNesInvoiceModal = false;
    },
  },
};
</script>
